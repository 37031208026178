import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import BodyClassName from "react-body-classname";
import Img from "gatsby-image";
import "../css/juster.css";
import "../css/animation.css";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet"
import PrevNext from '../components/prevnext';
import SEO from '../components/seo';

import {FaTwitter} from "react-icons/fa";
import {FaFacebook} from "react-icons/fa";
import {FaLinkedin} from "react-icons/fa";

import { ShareButtonIconOnly, ShareBlockStandard } from "react-custom-share";

export default function Template(props) {
  const { prev, next } = props.pageContext;
  const { markdownRemark } = props.data; // data.markdownRemark holds your post data
  const {
    frontmatter,
    html,
    fields: {
      readingTime: { text }
    },
    excerpt
  } = markdownRemark;
  let featuredImgFluid = frontmatter.featuredImage.childImageSharp.fluid;
  let metaImage = frontmatter.featuredImage.childImageSharp.fluid.src;
   const shareBlockProps = {
      url: "https://www.JustEZ.app"+frontmatter.path,
      button: ShareButtonIconOnly,
      buttons: [
        { network: "Twitter", icon: FaTwitter },
        { network: "Facebook", icon: FaFacebook },
        { network: "Linkedin", icon: FaLinkedin }
      ],
      text: frontmatter.title,
      // buttonClassName: customStyles
    };
  return (
    <>
      <SEO title={frontmatter.title} description={frontmatter.description} image={metaImage} pathname={frontmatter.path} article={true}/>
      <BodyClassName className="blog" />
      <Header></Header>
      <Helmet>
          <meta name = "keywords" content={frontmatter.tags}/>
        </Helmet>
      <div className="integration_pg_banner">
        <div class="container">
          <div class="blog-title-single">
            <h2>{frontmatter.title}</h2>
            <div class="social">
            <h6>
              {frontmatter.date} . {text}
            </h6>
            <ShareBlockStandard {...shareBlockProps} />
            </div>
          </div>
          
        </div>
        
        <Img fluid={featuredImgFluid} />
        <p className="figcaption">{frontmatter.caption ?frontmatter.caption: ''}</p>
        <div class="container">
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          
        </div>
        {/* <ShareLink postNode={frontmatter} description="JustEZ"/> */}
        <div class="container">
          <PrevNext prev={prev && prev.node} next={next && next.node} />
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt(pruneLength: 250)
      frontmatter {
        date(formatString: "MMM DD, YYYY")
        path
        title
        tags
        description
        caption
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
              src
            }
          }
        }
      }
      fields {
        readingTime {
          text
        }
      }
    }
  }
`;
