import React from 'react'
const PrevNext = (props) => {

    const { prev, next } = props

    return (
        <div class="blog-post-content pagination"><div>
            {prev && <div><span>Previous</span><a href={prev.frontmatter.path}>
                 {" "}
                {prev.frontmatter.title}</a></div>}</div><div>
            {next && <div class="txt-right"><span>Next</span><a href={next.frontmatter.path}>
                  {" "} {next.frontmatter.title}

            </a></div>}</div>
        </div>
    )
}

export default PrevNext;